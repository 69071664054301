:root {
    --blue-300: #146B9D;
    --blue-200: #2D9CDB;
    --blue-100: #9CDBFF;

    --black-300: #252525;
    --black-200: #5B5B5B;
    --black-100: #D9D9D9;

    --orange-300: #D0871A;
    --orange-200: #FDA726;
    --orange-100: #FFE6C1;

    --red-300: #7C2222;
    --red-200: #D62020;
    --red-100: #FF8D8D;

    --green-300: rgb(46, 157, 97);
    --green-200: rgb(25, 221, 115);
    --green-100: rgb(160, 255, 204);

    /* Text-size styles */
    --mobile-tiny: 12px;
    --mobile-micro: 10px;
    --mobile-small: 14px;
    --mobile-paragraph: 20px;
    --mobile-heading-1: 32px;
    /* h1 and h2 same size since we don't really use h1 ... */
    --mobile-heading-2: 32px;
    --mobile-heading-3: 24px;
    --mobile-heading-4: 20px;
    --mobile-paragraph-fine-print: 12px;

    --space-h: 4px;
    --space-1: 8px;
    --space-2: 16px;
    --space-3: 24px;
    --space-4: 32px;
    --space-5: 40px;

    --max-width: 960px;
}

.orange300 {
    color: var(--orange-300)
}

.vertical-1 {
    margin: var(--space-1) 0;
}

.vertical-2 {
    margin: var(--space-2) 0;
}

.bottom-1 { margin-bottom: var(--space-1); }
.bottom-2 { margin-bottom: var(--space-2); }
.bottom-3 { margin-bottom: var(--space-3); }
.bottom-4 { margin-bottom: var(--space-4); }
.bottom-4 { margin-bottom: var(--space-5); }

